.home {
  background: #8a2387; /* fallback for old browsers */
  @keyframes bgcolor {
    0% {
      background-color: #3498db;
    }

    50% {
      background-color: #27ae60;
    }

    100% {
      background-color: #e67e22;
    }
  }

  -webkit-animation: bgcolor 10s infinite;
  animation: bgcolor 10s infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;

  min-height: calc(100vh - 56px); // subtract navbar height
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  h1 {
    padding-top: 30px;
    display: inline-block;
    color: #2c3e50;
    max-width: 80%;
  }
  p {
    padding-right: 10px;
    padding-left: 10px;
  }
  .info-box {
    border-radius: 6px;
    width: 800px;
    max-width: 80%;
    margin-top: 80px;
    padding-bottom: 30px;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    background-color: rgba(255, 255, 255, 0.7);
    animation: fadein 0.5s;
    animation-timing-function: ease-out;
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .react-autosuggest__container {
    width: 60%;
  }
  .react-autosuggest__input {
    width: 100%;
    height: 40px;
    margin: 10px 0px 10px 0px;
  }
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 49px; // Margin + input height - 1
    width: 100%;
    // max-width: 240px;
  }
}
footer {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
