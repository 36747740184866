.tracking-page {
  @import url('https://fonts.googleapis.com/css?family=Rubik:300,400');
  h1,
  h4 {
    text-align: center;
  }
  h4 {
    font-family: 'Rubik', sans-serif;
  }

  .errors {
    margin-top: 10px;
    text-align: center;
    white-space: pre-line;
  }
  .no-bus {
    margin-left: 20px;
    margin-right: 20px;
  }
  .table {
    font-family: 'Rubik', sans-serif;
    td {
      padding-top: 2rem;
      padding-bottom: 2rem;
      font-size: 20px;
      @media screen and(max-width:500px) {
        font-size: 5vw;
        .btn {
          font-size: 12px;
        }
      }
      border-top: none;
      font-family: 'Lato', sans-serif;
    }
  }

  .no-wrap {
    word-break: keep-all !important;
  }

  .stop_name {
    margin-top: 20px;
  }

  .refresh-text {
    text-align: center;
    h5 {
      font-weight: 400;
      @media screen and(max-width:500px) {
        font-size: 3.5vw;
      }
    }
    opacity: 0;
    transition: opacity 0.75s;
  }

  .fadeIn {
    opacity: 1;
  }
  .fadeOut {
    opacity: 0;
    transition: none;
  }

  .refresh-btn {
    color: #3498db;
    outline: none;
    background-color: transparent;
    border: none;
    padding: 0px;
    transition: color 0.5s;
    &:hover {
      color: #2980b9;
      outline: none;
    }
  }
}
